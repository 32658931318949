import React from "react";
import Slider from "react-slick";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Progress,
  Badge,
} from "reactstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../assets/img/itaesa.png"; // Ganti dengan path logo Anda
import { Line, Bar, Chart } from "react-chartjs-2";
import { formatNumberToThousands } from "utils/func";

function Graph({ item }) {
  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  let chartExample1 = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: ["ItaEsa", "Lain", "Abuabu", "unidentified"],
        datasets: [
          {
            label: "pemilih",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: item.data,
          },
        ],
      };
    },

    options: chart1_2_options,
  };

  return (
    <div style={{ padding: "0 10px" }}>
      <Card
        className="shadow-sm"
        style={{
          borderRadius: "10px",
          backgroundColor: "#f8f9fa",
          border: "1px solid #e9ecef",
          height: "auto",
          width: "100%",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <CardBody>
          <Row>
            <Col>
              <CardTitle
                tag="h5"
                className="mb-2"
                style={{
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                  color: "#007bff",
                }}
              >
                {item.kecamatan}
              </CardTitle>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                <Badge color="primary">
                  {formatNumberToThousands(item.countDpt)}
                </Badge>
                <p> orang</p> <Badge>DPT</Badge>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center" xs="12">
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                padding: 5,
              }}
            >
              <Bar
                data={chartExample1["data1"]}
                options={chart1_2_options}
                width={100}
              />
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default Graph;
