import swal from "sweetalert";

import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
} from "../utils/dispatch/index";

import { API_TIMEOUT, LOGIN, USERS } from "../utils";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { logApp } from "utils/func";
import { formatDateTime } from "utils/func";
import { refreshToken } from "utils/func";

export const LOGIN_USER = "LOGIN_USER";
export const CHECK_LOGIN = "CHECK_LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

function kirimPesan(nama, nohp, username) {
  //   var url = "https://pati.wablas.com/api/send-message";
  var url = "https://kudus.wablas.com/api/send-message";
  var Tokenwhatsap =
    "cwmRbIhODIa7WcS4M06b8xYRTj8OpPqQXHHjcUjLfWqDecH8eFmHkk98qSGDud9I";

  //082237839018

  var pesanKu =
    `*Laporan Aktifitas Akun*` +
    `\n\ \n\Soda Molek To'o/Te'o *` +
    nama +
    "*" +
    `\n\ \n\Uername anda baru saja melakukan *login* dengan ` +
    nohp +
    " pada " +
    formatDateTime(new Date());
  var data = {
    phone: nohp,
    message:
      pesanKu +
      `\n\ \n\ Bersama Kita Bisa \n\ 💥💥💥 Meledak - Melayani dengan Dengan Kasih`,
  };

  var options_user = {
    method: "POST",
    headers: {
      Authorization: Tokenwhatsap,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  var response = fetch(url, options_user)
    .then((response) => response.text())
    .then((data) => console.log(""));
}

export const loginUser = (email, password, setLoginProses, history) => {
  axios({
    method: "post",
    url: LOGIN,
    timeout: API_TIMEOUT,
    data: { email: email, password: password },
  })
    .then((response) => {
      if (response.status !== 200) {
        // ERROR
      } else {
        //BERHASIL

        const data = response.data;

        //cek apakah yang bersangkutan terdata di sim obat
        if (data.accessToken) {
          const decoded = jwt_decode(data.accessToken);

          swal("Success", decoded.nama + " berhasil login", "success");
          const dataUser = data;
          let curr = {};
          curr.nama = decoded.nama;

          curr.desa = decoded.desa;
          curr.status =
            decoded.status == 1
              ? "super admin"
              : decoded.status == 2
              ? "admin"
              : "user";
          curr.avatar = decoded.profile;
          curr.statusKu = decoded.status == 3 ? "user" : "admin";

          curr.email = decoded.email;

          curr.uid = decoded.userId;
          curr.noHp = decoded.noHp;
          localStorage.setItem("user", JSON.stringify(curr));

          logApp(decoded.userId, "Login");
          kirimPesan(curr.nama, curr.noHp, curr.email);

          setLoginProses(false);
          if (decoded.status == 2 || decoded.status == 1) {
            history.push("/admin/dashboard");
          } else if (decoded.status == 3) {
            history.push("/admin/dashboardUser");
          }
        } else {
          const dataKu = data.data;
          setLoginProses(false);
          swal("Failed", dataKu, "error");
        }
      }
    })
    .catch((error) => {
      // ERROR
      //  console.log(creds.email, resDB().uid);
      setLoginProses(false);
      swal("Gagal", error.message, "error");
    });
};

export const loginUser2 = (email, password) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGIN_USER);
    axios({
      method: "post",
      url: LOGIN,
      timeout: API_TIMEOUT,
      data: { username: email, password: password },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, LOGIN_USER, "error");
        } else {
          //BERHASIL
          if (response.data.success == 0) {
            swal("Failed", "email dan password anda salah", "error");
            dispatchError(dispatch, LOGIN_USER, "error");
          } else {
            dispatchSuccess(
              dispatch,
              LOGIN_USER,
              response.data.data ? response.data.data : []
            );
          }
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, LOGIN_USER, error);
        swal("Failed", "Anda Bukan Admin", "error");
      });
  };
};

export const checkLogin = (history) => {
  if (window.localStorage.getItem("user")) {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const status = user.status;

    if (status == "user") {
      history.push({ pathname: "/admin/dashboardUser" });
    } else if (status == "admin" || status == "super admin") {
      history.push({ pathname: "/admin/dashboard" });
    } else {
      history.push({ pathname: "/login" });
    }
  } else {
    history.push({ pathname: "/login" });
  }
};

export function logoutUser(history, uid) {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  axios.defaults.headers.common["Authorization"] = "";
  logApp(uid, "Logout");
  axios.delete(USERS, {
    credentials: "include",
  });

  history.push({ pathname: "/login" });
}
