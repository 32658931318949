import { checkLogin } from "actions/AuthAction";
import { loginUser } from "../actions/AuthAction";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import swal from "sweetalert";
import Logo from "../assets/img/itaesa.png";
import CoverImage from "../assets/img/cover.png";
import ReCAPTCHA from "react-google-recaptcha";
import { removeExtraSpaces } from "utils/func";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import show from "../assets/img/show-password.png";
import hide from "../assets/img/hide.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // State untuk mengontrol tampilan password

  const history = useHistory();
  const [loginProses, setLoginProses] = useState(false);

  useEffect(() => {
    checkLogin(history);
  }, []);

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function handleCaptchaChange(value) {
    setCaptchaToken(value);
  }

  function handleSubmit(event) {
    setLoginProses(true);
    event.preventDefault();

    if (removeExtraSpaces(email) && password && captchaToken) {
      loginUser(removeExtraSpaces(email), password, setLoginProses, history);
    } else {
      swal("Failed", "Maaf Email, Password, dan Captcha Harus Diisi", "error");
      setLoginProses(false);
    }
  }

  return (
    <div
      style={{
        background: "#fff",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Row
        className="justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <Col xs="12" md="10" lg="8">
          <Card
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              border: "none",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <Row className="no-gutters">
              <Col xs="12" className="d-block d-md-none">
                <img
                  src={CoverImage}
                  alt="Cover"
                  className="img-fluid"
                  style={{
                    borderRadius: "10px 10px 0 0",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
              </Col>
              <Col md="6" className="d-none d-md-block">
                <img
                  src={CoverImage}
                  alt="Cover"
                  className="img-fluid h-100"
                  style={{
                    borderRadius: "10px 0 0 10px",
                    objectFit: "cover",
                    objectPosition: "right",
                  }}
                />
              </Col>
              <Col
                xs="12"
                md="6"
                className="p-4 d-flex flex-column justify-content-center"
              >
                <div className="text-center mb-4 d-none d-md-block">
                  <img
                    src={Logo}
                    className="rounded mx-auto d-block"
                    alt="logo"
                    style={{ width: 70, marginBottom: 20 }}
                  />
                  <h4 className="mb-0">ITA ESA DIGITAL</h4>
                  <span style={{ fontSize: 12, color: "#666" }}>
                    Aplikasi Manajemen Data Pendukung
                  </span>
                </div>
                <CardBody>
                  <form onSubmit={(event) => handleSubmit(event)}>
                    <FormGroup>
                      <Label for="email">Nomor Handphone</Label>
                      <Input
                        type="number"
                        name="text"
                        value={email}
                        placeholder="Enter No HP"
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="password">Password</Label>
                      <InputGroup>
                        <Input
                          type={showPassword ? "text" : "password"} // Ubah tipe input berdasarkan state
                          name="password"
                          value={password}
                          placeholder="Enter Password"
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            onClick={() => setShowPassword(!showPassword)} // Toggle show/hide password
                            style={{
                              cursor: "pointer",
                              padding: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {showPassword ? (
                              <img
                                src={show}
                                alt="show"
                                style={{ width: "24px", height: "24px" }}
                              />
                            ) : (
                              <img
                                src={hide}
                                alt="hide"
                                style={{ width: "24px", height: "24px" }}
                              />
                            )}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="text-center">
                      <ReCAPTCHA
                        sitekey="6Le9LyMqAAAAAOnXfPx0g7xdjYXNws_svZQeU7Rk"
                        onChange={handleCaptchaChange}
                      />
                    </FormGroup>

                    {loginProses ? (
                      <Button color="primary" type="submit" disabled block>
                        <Spinner size="sm" color="light" /> Loading
                      </Button>
                    ) : (
                      <Button color="primary" type="submit" block>
                        Login
                      </Button>
                    )}
                  </form>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
