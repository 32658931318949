import React, { useEffect, useState } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import cities from "./mock";

//import roteNdaoGeo from '../../../../assets/jsonData/kab_Rote.json';
import roteNdaoGeo from "../../assets/jsonData/kab_Rote.json";

import AnimateNumber from "react-animated-number";
import s from "./am4chartMap.module.scss";
import "./tooltip.css";
// Fungsi untuk menghasilkan warna acak
import { GET_PENDUKUNG_DESA } from "../../utils";
import axios from "axios";

const Am4chartMap = () => {
  const [listDesa, setListDesa] = useState([]);
  const features = roteNdaoGeo.features;
  const [mapCombine, setMapCombine] = useState();

  const combineData = () => {
    var desaku = [];
    var desa;
    for (let i = 0; i < features.length; i++) {
      desa = features[i];

      var pendukungDesa = listDesa.filter((item) => {
        return item.map.toUpperCase() === desa.properties.DESA.toUpperCase();
      });

      if (pendukungDesa.length > 0) {
        desa.properties.countDpt = pendukungDesa[0].countDpt;
        desa.properties.countPendukung = pendukungDesa[0].countPendukung;
        desa.properties.countNonPendukung = pendukungDesa[0].countNonPendukung;
        desa.properties.countAbuabu = pendukungDesa[0].countAbuabu;
        desa.properties.countUnidentified = pendukungDesa[0].countUnidentified;
        desa.properties.color = "#f5bf23";
      } else {
        desa.properties.countDpt = 0;
        desa.properties.countPendukung = 0;
        desa.properties.countNonPendukung = 0;
        desa.properties.countAbuabu = 0;
        desa.properties.countUnidentified = 0;
        desa.properties.color = "#8B0000";
      }

      // return [r['desa'], jmlKrr, jmlKrt, jmlKrst, ibuDesa.length, colorHamil];

      desaku.push(desa);
    }

    const datamap = {
      type: "FeatureCollection",
      features: desaku,
    };

    setMapCombine(datamap);
  };

  function getColorByValue(value) {
    return value;
  }

  const getListDesa = async () => {
    var data1 = [];
    try {
      const response = await axios.get(GET_PENDUKUNG_DESA);
      data1 = response.data.data;
      if (data1.length > 0) {
        setListDesa(data1);
      } else {
        setListDesa([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getListDesa();
  }, []);

  useEffect(() => {
    if (listDesa) {
      combineData();
    }
  }, [listDesa]);

  useEffect(() => {
    if (mapCombine) {
      let map = am4core.create("map", am4maps.MapChart);
      map.geodata = mapCombine;
      map.percentHeight = 90;
      map.dy = 10;
      //   map.projection = new am4maps.projections.AlbersUsa();
      let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      map.homeZoomLevel = 1;
      map.zoomControl = new am4maps.ZoomControl();
      map.zoomControl.layout = "horizontal";
      map.zoomControl.align = "left";
      map.zoomControl.valign = "bottom";
      map.zoomControl.dy = 30;
      map.zoomControl.dx = 30;
      map.zoomControl.contentHeight = 20;
      map.zoomControl.minusButton.background.fill = am4core.color("#C7D0FF");
      map.zoomControl.minusButton.background.stroke = am4core.color("white");
      map.zoomControl.minusButton.label.fontWeight = 600;
      map.zoomControl.minusButton.label.fontSize = 22;
      map.zoomControl.minusButton.scale = 0.75;
      map.zoomControl.minusButton.label.scale = 0.75;
      map.zoomControl.plusButton.background.fill = am4core.color("#C7D0FF");
      map.zoomControl.plusButton.background.stroke = am4core.color("#6979C9");
      map.zoomControl.plusButton.label.fontWeight = 600;
      map.zoomControl.plusButton.label.fontSize = 22;
      map.zoomControl.plusButton.label.align = "center";
      map.zoomControl.plusButton.scale = 0.75;
      map.zoomControl.plusButton.label.scale = 0.75;
      map.zoomControl.plusButton.dx = 5;
      let plusButtonHoverState =
        map.zoomControl.plusButton.background.states.create("hover");
      plusButtonHoverState.properties.fill = am4core.color("#354D84");
      let minusButtonHoverState =
        map.zoomControl.minusButton.background.states.create("hover");
      minusButtonHoverState.properties.fill = am4core.color("#354D84");
      let polygonTemplate = polygonSeries.mapPolygons.template;

      polygonTemplate.tooltipHTML = `
    <div className="custom-tooltip">
      <b>{DESA} - {KECAMATAN}</b>
      <br>DPT : {countDpt} </b>
      <br>ITA ESA : {countPendukung} </b>
      <br>Non Pendukung : {countNonPendukung} </b>
      <br>Abu - abu : {countAbuabu} </b>
      <br>Unidentified : {countUnidentified} </b>
    </div>
    `;

      //

      //polygonTemplate.propertyFields.fill = 'SYMB';
      polygonSeries.events.on("datavalidated", () => {
        polygonSeries.mapPolygons.each((polygon) => {
          let color = polygon.dataItem.dataContext.color;
          polygon.fill = color;
        });
      });

      //

      //polygonTemplate.fill = am4core.color('red');
      polygonTemplate.stroke = am4core.color("white");
      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = am4core.color("blue");

      //

      //

      let citySeries = map.series.push(new am4maps.MapImageSeries());
      citySeries.data = cities;
      citySeries.dataFields.value = "size";
      let city = citySeries.mapImages.template;
      city.nonScaling = true;
      city.propertyFields.latitude = "latitude";
      city.propertyFields.longitude = "longitude";
      let circle = city.createChild(am4core.Circle);
      circle.fill = am4core.color("#C7D0FF");
      circle.strokeWidth = 0;
      let circleHoverState = circle.states.create("hover");
      circleHoverState.properties.strokeWidth = 1;
      circle.tooltipText = "{tooltip}";
      circle.propertyFields.radius = "size";
      // return () => {
      //   if (map) {
      //     map.dispose();
      //   }
      // };
    }
  }, [mapCombine]);

  return (
    <div className={s.mapChart}>
      <div className={s.stats}>
        <h6 className="mt-1">GEO-LOCATIONS</h6>
        <p className="h3 m-0"></p>
      </div>

      <div className={s.map} id="map"></div>
    </div>
  );
};

export default Am4chartMap;
