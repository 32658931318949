import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Badge,
  Progress,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { logApp } from "utils/func";
import { refreshToken } from "utils/func";

import { GET_PERDESA } from "utils";

import { POST_PENDUKUNG } from "utils";
import { formatDate } from "utils/func";
import { formatNumberToThousands } from "utils/func";

function Pendukung_Desa() {
  const [desa, setDesa] = useState("");

  const [modalOpen2, setModalOpen2] = useState(false);
  const [dokumen, setDokumen] = useState("");
  const history = useHistory();

  const [status, setStatus] = useState();
  const [tanggal, setTanggal] = useState("");
  const [catatan, setCatatan] = useState("");
  const [idDpt, setIdDpt] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [listPerDesa, setListPerDesa] = useState([]);
  const [cari, setCari] = useState("");
  const [listCari, setListCari] = useState("");

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        setDesa(user.desa);

        logApp(user.uid, "DashboardUser");
        refreshToken(history, setAccessToken, user.uid);
      } else {
        history.push("/login");
      }
    } else {
      refreshToken(history, setAccessToken, currentUser.uid);
      logApp(currentUser.uid, "DashboardUser");
    }
  }, []);

  useEffect(() => {
    if (cari) {
      const filtered = listPerDesa.filter((item) =>
        item.nama.toLowerCase().includes(cari.toLowerCase())
      );
      setListCari(filtered);
    }
  }, [cari]);

  useEffect(() => {
    if (currentUser) {
      ambilPerdesa(desa);
    }
  }, [currentUser]);

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };

  const ambilPerdesa = (id) => {
    axios({
      method: "post",
      url: GET_PERDESA,
      timeout: API_TIMEOUT,
      credentials: "include",
      data: { desa: id },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setListPerDesa(data);
          setListCari(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const prosesStatus = (status) => {
    if (status == 1) {
      return <Badge color="success">Ita Esa</Badge>;
    } else if (status == 3) {
      return <Badge color="warning">Belum Memutuskan</Badge>;
    } else if (status == 2) {
      return <Badge color="danger">Paket Lain</Badge>;
    } else {
      return <Badge>Unidentified</Badge>;
    }
  };

  const handleSubmit = async (event) => {
    if (idDpt && status && tanggal && catatan) {
      const formData = new FormData();
      formData.append("photo", dokumen);

      formData.append("idDpt", idDpt);
      formData.append("status", status);
      formData.append("tanggal", tanggal);

      formData.append("catatan", catatan);
      formData.append("idInputor", currentUser.uid);

      await axios({
        method: "post",
        url: POST_PENDUKUNG,
        timeout: API_TIMEOUT,
        data: formData,
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            swal("Failed", "ada kesalahan pada server", "error");
          } else {
            //BERHASIL
            swal("Success", "Berita berhasil diposting", "success");
            ambilPerdesa(desa);

            onModalClose();
          }
        })
        .catch((error) => {
          // ERROR
          swal("Failed", "Ada kesalahan pada server", "error");
        });

      setSaveLoading(false);
    } else {
      swal("Failed", "Ada data yang belum diinput", "error");
      setSaveLoading(false);
    }
  };

  const onModalClose = () => {
    setStatus();
    setDokumen("");

    setTanggal("");
    setCatatan("");
    setIdDpt("");
    setModalOpen2(false);
  };

  const handleSimpan = () => {
    setSaveLoading(true);
    handleSubmit();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <Col md={6}>
                  <Label>Ketik Untuk Cari Nama Pemilih</Label>

                  <Input
                    value={cari}
                    onChange={(e) => setCari(e.target.value)}
                    style={{ color: "black" }}
                    type="text"
                  />
                </Col>
                <Col className="text-right" md={3}>
                  <Badge size="lg" color="danger">
                    {desa}
                  </Badge>{" "}
                  <Badge color="warning">
                    {formatNumberToThousands(listPerDesa.length)}
                  </Badge>
                  <Badge>DPT</Badge>
                </Col>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>nama</th>
                      <th>Gender</th>

                      <th>Usia</th>
                      <th>Status</th>
                      <th>Tanggal</th>
                      <th>Keterangan</th>
                      <th>Catatan</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listCari &&
                      listCari.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.nama}</td>

                          <td>{item.gender}</td>
                          <td>{item.usia}</td>
                          <td>{prosesStatus(item.status)}</td>
                          <td>{item.tanggal && formatDate(item.tanggal)}</td>
                          <td>{item.keterangan}</td>
                          <td>{item.catatan}</td>
                          <td>
                            <Button
                              color={"primary"}
                              size="sm"
                              onClick={() => {
                                setIdDpt(item.id);
                                setModalOpen2(true);
                              }}
                            >
                              Input
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          size="lg"
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div className="headline-2">{"Input Data"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Tanggal pendataan
              </Label>
              <Col md={9}>
                <Input
                  value={tanggal}
                  onChange={(e) => setTanggal(e.target.value)}
                  style={{ color: "black" }}
                  type="date"
                />
              </Col>
            </FormGroup>

            <div>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Status
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="select"
                      name="liga"
                      value={status}
                      onChange={(event) => {
                        setStatus(event.target.value);
                      }}
                    >
                      <option value={""}>- pilih -</option>
                      <option value={1}>Ita Esa</option>
                      <option value={2}>Paket Lain</option>

                      <option value={3}>Belum Menentukan Pilihan</option>
                    </Input>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md={3} className="text-md-right" for="markdown-editor">
                  Catatan/Keterangan
                </Label>
                <Col md={9}>
                  <Input
                    value={catatan}
                    onChange={(e) => setCatatan(e.target.value)}
                    style={{ color: "black" }}
                    type="textarea"
                    rows="4"
                  />
                </Col>
              </FormGroup>
            </div>

            <FormGroup row>
              <Label lg="3" className="text-md-right mt-3">
                {"Dokumen atau Foto yang ingin diupload  (gambar)"}
              </Label>
              <Col lg="9">
                <div>
                  <Input
                    id="upload"
                    type="file"
                    accept={"image/*"}
                    onChange={onChangeDok}
                    style={{ color: "black" }}
                  />
                  <label
                    id="upload-label"
                    htmlFor="upload"
                    className={`font-weight-light text-muted `}
                  >
                    {dokumen ? dokumen.name : "Pilih file"}
                  </label>
                  <div className="input-group-append">
                    <label
                      htmlFor="upload"
                      className="btn btn-light m-0 rounded-pill px-4"
                    >
                      <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                    </label>
                  </div>
                </div>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  onModalClose();
                  setModalOpen2(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ margin: 5 }}></div>
              {saveLoading ? (
                <Button className="btn-rounded" size="sm">
                  {" "}
                  {"Simpan ..."}
                </Button>
              ) : (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={handleSimpan}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Pendukung_Desa;
