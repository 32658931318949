import React from "react";
import Slider from "react-slick";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Progress,
  Badge,
} from "reactstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../assets/img/itaesa.png"; // Ganti dengan path logo Anda
import GraphKu from "./GraphKu";

function KecamatanCarousel({ kecamatanData }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div style={{ padding: "20px" }}>
      <Slider {...settings}>
        {kecamatanData.map((item, index) => (
          <GraphKu item={item} key={index} />
        ))}
      </Slider>
    </div>
  );
}

export default KecamatanCarousel;
