import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Badge,
  Progress,
} from "reactstrap";

import axios from "axios";

import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";

import { toProperCase } from "utils/func";

import { logApp } from "utils/func";

import Map from "../components/am4chartMap/am4chartMap_Pilkada";
import { GET_PILKADA2018 } from "utils";
import Sasando from "../assets/img/Sasando.png";
import RNB from "../assets/img/RNB.png";
import Lentera from "../assets/img/Lentera.png";
import Lontar from "../assets/img/Lontar.png";
import { Line, Bar } from "react-chartjs-2";
import pilkada2008 from "../assets/jsonData/2008.json";
import pilkada2013 from "../assets/jsonData/2013.json";
import { formatNumberToThousands } from "utils/func";
import { GET_PILKADA2018_TOTAL } from "utils";
import { data } from "jquery";
import { refreshToken } from "utils/func";

function Pilkada() {
  const [dokumen, setDokumen] = useState("");
  const history = useHistory();

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [listDesa, setListDesa] = useState([]);
  const [pilLabel2008, setLabelPil2008] = useState([]);
  const [pilData2008, setDataPil2008] = useState([]);
  const [pilData20082, setDataPil20082] = useState([]);
  const [pil2013] = useState({
    label: ["Omega", "Pasti", "Sama", "Debes", "Lentera"],
    data: [15458, 9030, 10198, 2848, 29445],
    total: 15458 + 9030 + 10198 + 2848 + 29445,
  });

  const [dataPil2018, setDataPil2018] = useState({
    label: [],
    data: [],
    total: 0,
  });

  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  let chartExample2 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: pilLabel2008,
        datasets: [
          {
            label: "Putaran 1",

            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: pilData2008,
          },
          {
            label: "Putaran 2",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef5",
            pointBorderColor: "rgba(255,255,255,1)",
            pointHoverBackgroundColor: "#1f8ef5",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: pilData20082,
          },
        ],
      };
    },
    options: chart1_2_options,
  };

  // #########################################
  // // // used inside src/views/Dashboard.js
  // #########################################
  let chartExample3 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

      return {
        labels: pil2013.label,
        datasets: [
          {
            label: "Suara",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: pil2013.data,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 120,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        ],
      },
    },
  };

  // #########################################
  // // // used inside src/views/Dashboard.js
  // #########################################
  const chartExample4 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

      return {
        labels: dataPil2018.label,
        datasets: [
          {
            label: "My First dataset",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#00d6b4",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00d6b4",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00d6b4",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataPil2018.data,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        ],
      },
    },
  };

  const getTotalPilkada2018 = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_PILKADA2018_TOTAL,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setListDesa([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL
          data1 = response.data.data;
          if (data1.length > 0) {
            let total = 0;
            const label = data1.map((item) => {
              return item.paket;
            });
            const data = data1.map((item) => {
              total = total + item.total;
              return item.total;
            });
            setDataPil2018({ label: label, total: total, data: data });
          } else {
            setListDesa([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setListDesa([]);
        alert("ada kesalahan pada server");
      });
  };

  const getListDesa = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_PILKADA2018,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setListDesa([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL
          data1 = response.data.data;
          if (data1.length > 0) {
            const dataku = remakeData(data1);

            setListDesa(dataku);
          } else {
            setListDesa([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setListDesa([]);
        alert("ada kesalahan pada server");
      });
  };

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Pilkada");
        refreshToken(history, setAccessToken, user.uid);
      } else {
        history.push("/login");
      }
    } else {
      logApp(currentUser.uid, "Pilkada");
      refreshToken(history, setAccessToken, currentUser.uid);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      const label = pilkada2008["2008"].map((item) => {
        return item.Paket;
      });
      const data1 = pilkada2008["2008"].map((item) => {
        return item.Putaran1;
      });
      const data2 = pilkada2008["2008"].map((item) => {
        return item.Putaran2;
      });

      setLabelPil2008(label);
      setDataPil2008(data1);
      setDataPil20082(data2);
      getListDesa();
      getTotalPilkada2018();
    }
  }, [currentUser]);

  const remakeData = (data) => {
    const updatedData = data.map((item) => {
      const totalSuara =
        item.totalSasando +
        item.totalLentera +
        item.totalRnB +
        item.totalLontar;

      // Determine the winner by finding the key with the maximum votes
      const votes = {
        totalSasando: item.totalSasando,
        totalLentera: item.totalLentera,
        totalRnB: item.totalRnB,
        totalLontar: item.totalLontar,
      };

      const pemenang = Object.keys(votes).reduce((a, b) =>
        votes[a] > votes[b] ? a : b
      );

      return {
        ...item,
        totalSuara,
        pemenang,
      };
    });
    return updatedData;
  };

  const pemenangPilkada = (pemenang) => {
    if (pemenang == "totalSasando") {
      return Sasando;
    }
    if (pemenang == "totalRnB") {
      return RNB;
    }
    if (pemenang == "totalLentera") {
      return Lentera;
    }
    if (pemenang == "totalLontar") {
      return Lontar;
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  <Badge color="primary">Pilkada 2018</Badge>
                </h5>
                <CardTitle tag="h4">
                  {formatNumberToThousands(62522)}{" "}
                  <Badge color="warning"> Pemilih</Badge>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  <Badge color="primary">Pilkada 2013</Badge>
                </h5>
                <CardTitle tag="h4">
                  {formatNumberToThousands(pil2013.total)}{" "}
                  <Badge color="warning"> Pemilih</Badge>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={chartExample3.data}
                    options={chartExample3.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  <Badge color="primary">Pilkada 2018</Badge>
                </h5>
                <CardTitle tag="h4">
                  {formatNumberToThousands(dataPil2018.total)}{" "}
                  <Badge color="warning"> Pemilih</Badge>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={chartExample4.data}
                    options={chartExample4.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <Map listHasil={listDesa} />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">
                  Distribusi Suara Perdesa{" "}
                  <Badge color="success">Pilkada 2018</Badge>
                </CardTitle>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Kecamatan</th>
                      <th>Desa</th>

                      <th>Total Pemilih</th>
                      <th>Total PHP</th>
                      <th>Pemenang</th>
                      <th>Sasando</th>
                      <th>RNB</th>

                      <th>Lentera</th>
                      <th>Lontar</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listDesa &&
                      listDesa.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.kecamatan)}</td>
                          <td>{toProperCase(item.desa)}</td>
                          <td>{formatNumberToThousands(item.totalPemilih)}</td>
                          <td>{formatNumberToThousands(item.totalPhp)}</td>

                          <td className="text-center">
                            {
                              <div
                                style={{
                                  width: 40,
                                  height: 40,
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={pemenangPilkada(item.pemenang)}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                                  }}
                                />
                              </div>
                            }
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.totalSasando}
                                    {") "}
                                    {Math.round(
                                      (item.totalSasando / item.totalSuara) *
                                        100
                                    )}
                                    % suara{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.totalSasando / item.totalSuara) *
                                      100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.totalSasando / item.totalSuara) * 100
                                  }
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.totalRnB}
                                    {") "}
                                    {Math.round(
                                      (item.totalRnB / item.totalSuara) * 100
                                    )}
                                    % suara{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.totalRnB / item.totalSuara) * 100 > 50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.totalRnB / item.totalSuara) * 100
                                  }
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.totalLentera}
                                    {") "}
                                    {Math.round(
                                      (item.totalLentera / item.totalSuara) *
                                        100
                                    )}
                                    % suara{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.totalLentera / item.totalSuara) *
                                      100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.totalLentera / item.totalSuara) * 100
                                  }
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.totalLontar}
                                    {") "}
                                    {Math.round(
                                      (item.totalLontar / item.totalSuara) * 100
                                    )}
                                    % suara{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.totalLontar / item.totalSuara) * 100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.totalLontar / item.totalSuara) * 100
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {item.latest_status != "Final" && (
                              <Button
                                color={
                                  item.latest_status === "Finish"
                                    ? "success"
                                    : "primary"
                                }
                                size="sm"
                                onClick={() => {
                                  "";
                                }}
                              >
                                Detail
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Pilkada;
