/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { toProperCase } from "utils/func";
import { logApp } from "utils/func";
import LogoItaEsa from "../assets/img/itaesa.png";

function UserProfile() {
  const [currentUser, setCurrentUser] = useState("");
  const history = useHistory();

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "User Profile");
      } else {
        history.push("/login");
      }
    }
  }, []);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Desa (disabled)</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          placeholder="Company"
                          type="text"
                          value={
                            currentUser &&
                            currentUser.desa &&
                            toProperCase(currentUser.desa)
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          defaultValue="michael23"
                          placeholder="Username"
                          type="text"
                          value={currentUser && currentUser.nama}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input
                          placeholder="mike@email.com"
                          type="email"
                          value={currentUser.email}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label> Nama</label>
                        <Input
                          defaultValue="Mike"
                          placeholder="Company"
                          type="text"
                          value={currentUser && currentUser.nama}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label> Nomor Handphone</label>
                        <Input
                          defaultValue="Mike"
                          placeholder="Company"
                          type="text"
                          value={currentUser && currentUser.noHp}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit">
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={
                        currentUser && currentUser.profile
                          ? currentUser.profile
                          : LogoItaEsa
                      }
                    />
                    <h5 className="title">{currentUser && currentUser.nama}</h5>
                  </a>
                  <p className="description">
                    {currentUser && currentUser.status == 1 ? "admin" : "user"}
                  </p>
                </div>
                <div className="card-description">
                  Aplikasi Ita Esa Untuk Perubahan
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
