import React, { useState, useEffect } from "react";
import axios from "axios";
import { GET_AKTIFITAS_USER } from "utils";
import { Badge, Button } from "reactstrap";

const AktifitasUser = ({ userId }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fungsi untuk memanggil API dan mendapatkan data
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(GET_AKTIFITAS_USER + userId, {
          params: { page },
        });

        setData((prevData) => [...prevData, ...response.data.data]); // Menggabungkan data lama dengan data baru
        setHasNextPage(response.data.hasNextPage);
      } catch (err) {
        setError("Terjadi kesalahan saat mengambil data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, page]);

  // Fungsi untuk memuat halaman berikutnya
  const loadMore = () => {
    if (hasNextPage) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div>
      <h2>Aktivitas Pengguna</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <p>
              <strong>Nama:</strong> {item.nama || "N/A"}
            </p>
            <p>
              <strong>Desa:</strong> {item.desa || "N/A"}
            </p>
            <p>
              <strong>Tanggal:</strong>{" "}
              {new Date(item.createdAt).toLocaleString()}
            </p>
            <p>
              <strong>Status:</strong>
              <Badge
                color={
                  item.status == 1
                    ? "success"
                    : item.status == 2
                    ? "danger"
                    : "Belum Menentukan Pilihan"
                }
              >
                {item.status == 1
                  ? "Ita Esa"
                  : item.status == 2
                  ? "Paket Lain"
                  : "Belum Menentukan Pilihan"}
              </Badge>
            </p>
            {/* Tambahkan informasi lain yang diperlukan */}
          </li>
        ))}
      </ul>
      {loading && <p>Memuat...</p>}
      {hasNextPage && !loading && (
        <Button size="sm" onClick={loadMore}>
          Muat Lebih Banyak
        </Button>
      )}
    </div>
  );
};

export default AktifitasUser;
