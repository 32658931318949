import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Progress,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";

import { toProperCase } from "utils/func";

import { logApp } from "utils/func";

import { GET_PENDUKUNG_DESA } from "utils";
import KecamatanCard from "components/card/Card";
import { GET_PENDUKUNG_KECAMATAN } from "utils";
import { refreshToken } from "utils/func";
import { formatNumberToThousands } from "utils/func";

function Pendukung() {
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [listDesa, setListDesa] = useState([]);
  const [kecamatanData, setKecamatanData] = useState([]);

  const getListDesa = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_PENDUKUNG_DESA,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setListDesa([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL
          data1 = response.data.data;

          if (data1.length > 0) {
            setListDesa(data1);
          } else {
            setListDesa([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setListDesa([]);
        alert("ada kesalahan pada server");
      });
  };

  const getListKecamatan = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_PENDUKUNG_KECAMATAN,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setKecamatanData([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL
          data1 = response.data.data;

          if (data1.length > 0) {
            const dd = data1.map((item) => {
              const label = [
                "Ita Esa",
                "Paket Lain",
                "Abu abu",
                "unidentified",
              ];
              const data = [
                item["countPendukung"],
                item["countNonPendukung"],
                item["countAbuabu"],
                item["countUnidentified"],
              ];
              const kecamatan = item["kecamatan"];
              const dpt = item["countDpt"];
              return {
                label: label,
                data: data,
                kecamatan: kecamatan,
                countDpt: dpt,
              };
            });

            setKecamatanData(dd);
          } else {
            setKecamatanData([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setKecamatanData([]);
        alert("ada kesalahan pada server");
      });
  };

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Pendukung");
        refreshToken(history, setAccessToken, user.uid);
      } else {
        history.push("/login");
      }
    } else {
      logApp(currentUser.uid, "Pendukung");
      refreshToken(history, setAccessToken, currentUser.uid);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      getListDesa();
      getListKecamatan();
    }
  }, [currentUser]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Total Pendukung Per Kecamatan</CardTitle>
              </CardHeader>

              <CardBody>
                <KecamatanCard kecamatanData={kecamatanData} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Daftar Pendukung Perdesa</CardTitle>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Kecamatan</th>
                      <th>Desa</th>

                      <th>Total DPT</th>
                      <th>Ita Esa</th>
                      <th>Non Pendukung</th>
                      <th>Belum Memutuskan</th>
                      <th>Unidentified</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listDesa &&
                      listDesa.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.kecamatan}</td>
                          <td>{toProperCase(item.desa)}</td>
                          <td>{formatNumberToThousands(item.countDpt)}</td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.countPendukung}
                                    {") "}
                                    {Math.round(
                                      (item.countPendukung / item.countDpt) *
                                        100
                                    )}
                                    % orang{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.countPendukung / item.countDpt) *
                                      100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.countPendukung / item.countDpt) * 100
                                  }
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.countNonPendukung}
                                    {") "}
                                    {Math.round(
                                      (item.countNonPendukung / item.countDpt) *
                                        100
                                    )}
                                    % orang{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.countNonPendukung / item.countDpt) *
                                      100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={(item.totalRnB / item.countDpt) * 100}
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.countAbuabu}
                                    {") "}
                                    {Math.round(
                                      (item.countAbuabu / item.countDpt) * 100
                                    )}
                                    % orang{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.countAbuabu / item.countDpt) * 100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.countAbuabu / item.countDpt) * 100
                                  }
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {formatNumberToThousands(
                                      item.countUnidentified
                                    )}
                                    {") "}
                                    {Math.round(
                                      (item.countUnidentified / item.countDpt) *
                                        100
                                    )}
                                    % orang{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.countUnidentified / item.countDpt) *
                                      100 >
                                    50
                                      ? "danger"
                                      : "success"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.countUnidentified / item.countDpt) *
                                    100
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {item.latest_status != "Final" && (
                              <Button
                                color={
                                  item.latest_status === "Finish"
                                    ? "success"
                                    : "primary"
                                }
                                size="sm"
                                onClick={() => {
                                  history.push(
                                    "/admin/pendukungku/" + item.desa
                                  );
                                }}
                              >
                                Detail
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Pendukung;
