/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, Chart } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Progress,
  Badge,
} from "reactstrap";

// core components

import swal from "sweetalert";

import { API_TIMEOUT } from "utils";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { logApp } from "utils/func";
import { formatDate } from "utils/func";
import { GET_USER_AKTIVITY } from "utils";
import { formatDateTime } from "utils/func";
import { logoutUser } from "actions/AuthAction";

import jwt_decode from "jwt-decode";

import { domain } from "utils";

import Map from "../components/am4chartMap/am4chartMap";
import { GET_DPT_KECAMATAN } from "utils";
import { USERS_TOKEN } from "utils";
import { refreshToken } from "utils/func";
import { GET_PENDUKUNG_KECAMATAN } from "utils";
import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { formatNumberToThousands } from "utils/func";

function Dashboard(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");

  const [dataGraph1, setDataGraph1] = useState({ label: [], dataGraph: [] });
  const [dataGraph2, setDataGraph2] = useState({
    label: [],
    label2: [],
    dataGraph1: [],
    dataGraph2: [],
    dataGraph3: [],
  });
  const [penggunaManek, setPenggunaManek] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const history = useHistory();
  const [notif, setNotif] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [kecamatanData, setKecamatanData] = useState([]);

  const [aktifitasPengguna, setAktifitasPengguna] = useState([]);

  const [expire, setExpire] = useState("");

  function formatAgeGroupCounts(data) {
    // Define the age groups and initialize the results structure
    const results = {
      labels: [
        "17_24",
        "25_31",
        "32_38",
        "39_45",
        "46_52",
        "53_59",
        "60_66",
        "67_73",
        "74_80",
        "81plus",
      ],
      data: new Array(10).fill(0), // Initializes the data array with zeros
    };

    // Process each entry to accumulate the counts for each age group
    data.forEach((entry) => {
      results.data[0] += entry.count17_24;
      results.data[1] += entry.count25_31;
      results.data[2] += entry.count32_38;
      results.data[3] += entry.count39_45;
      results.data[4] += entry.count46_52;
      results.data[5] += entry.count53_59;
      results.data[6] += entry.count60_66;
      results.data[7] += entry.count67_73;
      results.data[8] += entry.count74_80;
      results.data[9] += entry.count81plus;
    });

    return results;
  }

  // Example usage
  // const ageGroupData = formatAgeGroupCounts(data);

  const getListKecamatan = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_PENDUKUNG_KECAMATAN,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setKecamatanData([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL
          data1 = response.data.data;

          if (data1.length > 0) {
            const dd = data1.map((item) => {
              const label = [
                "Ita Esa",
                "Paket Lain",
                "Abu abu",
                "unidentified",
              ];
              const data = [
                item["countPendukung"],
                item["countNonPendukung"],
                item["countAbuabu"],
                item["countUnidentified"],
              ];
              const kecamatan = item["kecamatan"];
              const dpt = item["countDpt"];
              return {
                label: label,
                data: data,
                kecamatan: kecamatan,
                countDpt: dpt,
              };
            });

            setKecamatanData(dd);
          } else {
            setKecamatanData([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setKecamatanData([]);
        alert("ada kesalahan pada server");
      });
  };

  const getAtifitas = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_USER_AKTIVITY,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setAktifitasPengguna([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL
          data1 = response.data.data;

          setAktifitasPengguna(data1);
        }
      })
      .catch((error) => {
        // ERROR
        setAktifitasPengguna([]);
        alert("ada kesalahan pada server");
      });
  };

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Dashboard");
        refreshToken(history, setAccessToken, user.uid);
      } else {
        history.push("/login");
      }
    } else {
      logApp(currentUser.uid, "Dashboard");
      refreshToken(history, setAccessToken, currentUser.uid);
    }
  }, []);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(USERS_TOKEN);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (currentUser) {
      ambilDptKecamatan();
      getListKecamatan();
      getAtifitas();
    }
  }, [currentUser]);

  const ambilDptKecamatan = () => {
    axios({
      method: "get",
      url: GET_DPT_KECAMATAN,
      timeout: API_TIMEOUT,
      data: { uid: currentUser.uid },
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return item.singk;
          });
          const dataGr = data.map((item) => {
            return item.countDpt;
          });
          const dataGr2_1 = data.map((item) => {
            return item.countLaki;
          });
          const dataGr2_2 = data.map((item) => {
            return item.countPerempuan;
          });

          const dataG = { label: label, dataGraph: dataGr };
          const dataku = formatAgeGroupCounts(data);

          setDataGraph2({
            label: label,
            label2: dataku.labels,
            dataGraph1: dataGr2_1,
            dataGraph2: dataGr2_2,
            dataGraph3: dataku.data,
          });
          setDataGraph1(dataG);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  let chartExample1 = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph1.label,
        datasets: [
          {
            label: "DPT",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph1.dataGraph,
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

      return {
        labels: dataGraph2.label,
        datasets: [
          {
            label: "Laki laki",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#d048b6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#d048b6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph2.dataGraph1,
          },
          {
            label: "Perempuan",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#5f8ef3",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#5f8ef3",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#5f8ef3",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph2.dataGraph2,
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph2.label2,
        datasets: [
          {
            label: "Usia",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph2.dataGraph3,
          },
        ],
      };
    },

    options: chart1_2_options,
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle tag="h2">Daftar pemilih Tetap</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          DPT
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Pria v Wanita
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Usia
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={chartExample1[bigChartData]}
                    options={chart1_2_options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <Map />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <h6 className="title d-inline">Informasi </h6>
                <p className="card-category d-inline"> terakhir kerja Tim</p>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <Table>
                    <tbody>
                      {kecamatanData.map((item) => (
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <div style={{ height: 10 }}></div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <p className="title">{item.kecamatan}</p>
                              <div style={{ width: 5 }}></div>
                              <Badge color="warning">
                                {formatNumberToThousands(item.countDpt)}
                              </Badge>
                              <div style={{ width: 5 }}></div>
                              <p className="text-muted">{"DPT"}</p>
                            </div>
                            <div className="d-flex flex-column mt-6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <p className="body-2">
                                  {" ("}
                                  {item.data[0]}
                                  {" orang ) "}
                                  {Math.round(
                                    (item.data[0] / item.countDpt) * 100
                                  )}
                                  %
                                </p>
                                <div style={{ width: 5 }}></div>
                                <Badge>{"Ita Esa"}</Badge>
                              </div>
                              <Progress
                                color={
                                  (item.data[0] / item.countDpt) * 100 > 50
                                    ? "success"
                                    : "danger"
                                }
                                className="progress-xs"
                                value={(item.data[0] / item.countDpt) * 100}
                              />
                            </div>
                            <div style={{ height: 10 }}></div>
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              color="link"
                              id="tooltip636901683"
                              title=""
                              type="button"
                              onClick={() => {
                                "";
                              }}
                            >
                              <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip636901683"
                              placement="right"
                            >
                              Detail
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Detail Aktifitas Pengguna</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Waktu</th>
                      <th>User</th>

                      <th className="text-center">Page</th>
                    </tr>
                  </thead>
                  <tbody>
                    {aktifitasPengguna &&
                      aktifitasPengguna.map((item) => (
                        <tr>
                          <td>{formatDateTime(item.createdAt)}</td>
                          <td>{item.nama}</td>

                          <td>{item.page}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
